import React, { Component } from 'react';

class Breadcrumb extends Component {
    render() {
        return (
            <section className="section breadcrumb-area bg-overlay d-flex align-items-center" style={{ height: '100px'}}>
                <div className="container">
                    
                </div>
            </section>
        );
    }
}

export default Breadcrumb;