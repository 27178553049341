import React, { Component } from 'react';

class BlogDetails extends Component {
    state = {
        data: [
            {
                title: "1. Общие положения",
                content: [
                    "Настоящая политика обработки персональных данных составлена в соответствии с требованиями Закона КР от 1 марта 2024 года № 58 «Об информации персонального характера» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые eBilim (далее – Оператор).",
                    "1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.",
                    "1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях приложения."
                ]
            },
            {
                title: "2. Основные понятия, используемые в Политике",
                content: [
                    "2.1. Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;",
                    "2.2. Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);",
                    "2.3. Приложение – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу.",
                    "2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий, и технических средств;",
                    "2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;",
                    "2.6. Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных",
                    "2.7. Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработок",
                    "2.8. Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю приложения eBilim",
                    "2.9. Пользователь – любой посетитель приложения eBilim",
                    "2.10. Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц",
                    "2.11. Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационнотелекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом",
                    "2.12. Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу",
                    "2.13. Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных"
                ]
            },
            {
                title: "3. Оператор может обрабатывать следующие персональные данные Пользователя",
                content: [
                    "3.1. Фамилия, имя, отчество;",
                    "3.2. Электронный адрес;",
                    "3.3. Номера телефонов;",
                    "3.4. Год, месяц, дата и место рождения, паспортные данные;",
                    "3.5. Фотографии;",
                    "3.6. Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные."
                ]
            },
            {
                title: "4. Цели обработки персональных данных",
                content: [
                    "4.1. Цель обработки персональных данных Пользователя — предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся в приложении."
                ]
            },
            {
                title: "5. Правовые основания обработки персональных данных",
                content: [
                    "5.1. Оператор обрабатывает персональные данные Пользователя только в случае их заполнения учебным заведением, в котором Пользователь обучается. Используя приложением eBilim Пользователь выражает свое согласие с данной Политикой.",
                    "5.2. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript)."
                ]
            },
            {
                title: "6. Порядок сбора, хранения, передачи и других видов обработки персональных данных",
                content: [
                    "Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.",
                    "6.1. Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.",
                    "6.2. Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства."
                ]
            },
            {
                title: "7. Трансграничная передача персональных данных",
                content: [
                    "7.1. Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.",
                    "7.2. Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных."
                ]
            },
            {
                title: "8. Заключительные положения",
                content: [
                    "8.1. Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты ebilim.edu@gmail.com.",
                    "8.2. В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией."
                ]
            }
        ]
    }
    render() {
        return (
            <div>
                {/* Single Blog Details */}
                <article className="single-blog-details">
                    {/* Blog Content */}
                    <div className="blog-content sApp-blog">
                        {/* Blog Details */}
                        <div className="blog-details">
                            {this.state.data.map((section, index) => (
                                <div key={index}>
                                    <h4 className="blog-title py-3">{section.title}</h4>
                                    {section.content.map((paragraph, pIndex) => (
                                        <p className="d-sm-block my-2" key={pIndex}>{paragraph}</p>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </article>
            </div>
        );
    }
}

export default BlogDetails;